import { Routes } from '@angular/router';
import {LayoutOneComponent} from './layouts/layout-one/layout-one.component';

export const rootRouterConfig: Routes = [
  // {
  //   path: '',
  //   component: LayoutOneComponent,
  // },
  // {
  //   path: '**',
  //   redirectTo: ''
  // }
  // {
  //   path: '',
  //   redirectTo: 'demos',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'demos',
  //   loadChildren: './main/main.module#DemoModule'
  // },
  // {
  //   path: 'layout',
  //   loadChildren: './layouts/layout.module#LayoutModule'
  // },
  // {
  //   path: '**',
  //   redirectTo: 'layout/jamz'
  // }
];

