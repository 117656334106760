import {Component, OnInit} from '@angular/core';
import {LandingFixService} from './shared/services/landing-fix.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private fix: LandingFixService
    ) {
    }

    ngOnInit() {
        this.fix.addFix();
    }

}
